import React, { useRef, useState } from "react";
import "./AcademyInternship.scss";
import CourseBanner from "../../../../Assets/AcademyInternship/CourseBanner.png";
import Header from "../../../Home/Header/Header";
import Footer from "../../../Home/Footer/Footer";
import EnrollSection from "./EnrollSection/EnrollSection";
import TicketSection from "./TicketSection/TicketSection";
import SpeakerSection from "./SpeakerSection/SpeakerSection";
import Courses from './Courses/Courses';
import UpcomingWorkshopSection from "./UpcomingWorkshop/UpcomingWorkshopSection";
import TestimonialSection from "./TestimonialSection/TestimonialSection";
import Form from "./FormSection/Form";
import { Button } from "primereact/button";

const AcademyInternship = () => {

    const [show, setShow] = useState(false);
    const formRef = useRef(null);
    const headerHeight = 120;

    const handleFormOpen = () => {
        setShow(true);

        setTimeout(() => {
            if (formRef.current) {
                const formPosition = formRef.current.getBoundingClientRect().top + window.scrollY - headerHeight;
                window.scrollTo({ top: formPosition, behavior: 'smooth' });
            }
        }, 0);
    }

    const handleFormClose = () => {
        setShow(false);
    }

    return (
        <>
            <Header />
            <div className="academy-internship-section">
                <div className="internship-sec">
                    <div className="internship-content w-full">
                        <div className="col p-5">
                            <div className="ic-heading pb-5">
                                Intensive Entrepreneurship Workshop with Capstone Project
                            </div>
                            <div className="ic-main-details">
                                <p>8 Week Course (Sept 22 - Nov 17, 2024)</p>
                                <div>
                                    <Button label="Enroll Now" onClick={handleFormOpen} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="internship-img-sec">
                        <img src={CourseBanner} alt="CourseBanner" />
                    </div>
                </div>

                <EnrollSection handleFormOpen={handleFormOpen} />

                {
                    show ? (
                        <>
                            <div ref={formRef}>
                                <Form handleFormClose={handleFormClose} />
                            </div>
                        </>
                    ) : (
                        <>
                            <TicketSection handleFormOpen={handleFormOpen} />
                            <SpeakerSection />
                            <Courses />
                            <TestimonialSection />
                            <UpcomingWorkshopSection />
                        </>
                    )
                }
            </div>
            <Footer />
        </>
    );
};

export default AcademyInternship;
