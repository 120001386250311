import { Button } from 'primereact/button';
import React from 'react';
import bannerImg from "../../../../Assets/Academy/BannerImage.png";
import "./AcademyBanner.scss";

const AcademyBanner = () => {
    return (
        <>
            <div className='s2l-banner-section mx-2'>
                <div className='banner-content'>
                    <h2>Welcome <span className="startup-text">Creative Teens</span><br /></h2>
                    <p>A unique entrepreneurship program empowering middle and high school students.</p>
                    <p>S2L Academy helps middle and high school students turn ideas into startups with hands-on learning.
                        <b> Think Differently:</b> Use First Principles Thinking.
                        <b> Manage Your Time:</b> Learn how to stay organized.
                        <b> Educational Tips:</b> Get advice for better grades.
                        <b> Start-Up Basics:</b> Understand how to launch and run a business. Unlock your potential with S2L Academy.
                    </p>
                    <Button className='purple-bg'><a href='https://courses.startup2launch.com/8-week-program' target="_blank" rel="noopener noreferrer">Enroll Now</a></Button>
                </div>


                <div className='banner-image'>
                    <img src={bannerImg} alt='People working around a table' />
                </div>
            </div>

        </>
    )
}

export default AcademyBanner