import React from 'react';
import "./Academy.scss";
import AcademyBanner from './Academy-Banner/AcademyBanner';
import Header from '../../Home/Header/Header';
import AcademyProgram from './Academy-Program/AcademyProgram';
import ProgramDetails from './Program-Details/ProgramDetails';
import JoinAcademy from './Join-Academy/JoinAcademy';
// import ContactUs from './Academy-Contact/ContactUs';
import Footer from '../../Home/Footer/Footer';
import AdBanner from '../../Home/AdBanner/AdBanner';
import AcademyPlan from './Academy-Plan/AcademyPlan';

const Academy = () => {
    return (
        <div>
            <Header />
            {/* <AdBanner /> */}
            <AcademyBanner />
            {/* <AcademyProgram /> */}
            <AcademyPlan />
            <ProgramDetails />
            <JoinAcademy />
            {/* <ContactUs /> */}
            <Footer />
        </div>
    )
}

export default Academy