import React from "react";
import "./TestimonialSection.scss";
import Slider from "react-slick";
import { Rating } from "primereact/rating";
import Truncate from "./Truncate";

const TestimonialSection = () => {
  const testimonialData = [
    {
      id: 1,
      name: "Arpita Ghosh",
      testimonial:
        "The workshop by S2L Academy was an awesome experience for my daughter to understand the basic concepts of entrepreneurship and the exam prep was a very useful session.",
      rating: 5,
    },
    {
      id: 2,
      name: "Prathyusha Allada",
      testimonial:
        "The MasterClass for High Schoolers program was very well-organized program. This event helps high school students in boosting their academic performance and future achievement, and highschoolers actively engaged throughout. We eagerly await the next session and 8-week program, which will definitely be equally amazing.",
      rating: 5,
    },
    {
      id: 3,
      name: "Naren Babu Guntupalli",
      testimonial:
        "Thanks for letting my son have the exposure to such a nice exercise. He was excited and has learned a lot in how to express ideas.",
      rating: 5,
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 855,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="testimonial-section" id="testimonial-section-academy">
      <div className="testimonial-section-layout ">
        <div className="testimonial-title text-center">
          <h2 className="text-center title font-bold">
            Experience of attending and feedback
          </h2>
        </div>

        <div className="testimonial-layout">
          <Slider {...settings}>
            {testimonialData.map((testimonial, index) => (
              <div
                className=" m-2 p-3 carousel-card gap-6 h-full"
                key={testimonial.id}
              >
                <div className="grid align-items-center justify-content-between p-2">
                  <div>
                    <h2>{testimonial.name}</h2>
                  </div>
                </div>

                <p className={`mt-0 mb-3 `}>
                  <Truncate content={testimonial.testimonial} />
                </p>
                <div className="testimonial-rating  my-4">
                  <Rating
                    value={testimonial.rating}
                    readOnly
                    cancel={false}
                    style={{ fontSize: "1.3em" }}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
