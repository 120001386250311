
import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faStar } from '@fortawesome/free-solid-svg-icons';
import './Review.scss'
import Truncate from '../../Academy/Academy-StudentsProfile/Academy-Internship/TestimonialSection/Truncate';

const Review = () => {

    const reviews = [
        // {
        //     name: 'Student',
        //     tag: "Laulama Schools",
        //     content: 'Being able to see what high school credits will need in order to pass because a lot of people might not know what they need or how much they already have.',
        // },
        {
            name: 'Mina',
            tag: "Patient - Arya Psychiatry",
            content: 'I was very impressed with the quality of technical services during my doctor’s visit. I was able to be seen right away…',
        },
        // {
        //     name: 'Corey Nakamura',
        //     tag: "Principal - Laulama Schools",
        //     content: 'This is different, something I never learned in my entire career in education.',
        // },
        // {
        //     name: 'Student',
        //     tag: "Laulama Schools",
        //     content: 'The hands-on approach using software. The books layout is also good. And, Laulama is pretty easy to use.',
        // },
        {
            name: 'Ram',
            tag: "Patient - Arya Psychiatry",
            content: 'Urgent psychiatry in less than two hours from very experienced and professional psychiatrists.',
        },
        // {
        //     name: 'Student',
        //     tag: "Laulama Schools",
        //     content: 'I am glad to have this as organized program so we can know stuff about ourselves and how to learn.',
        // },
        // {
        //     name: 'Leila Kobayashi',
        //     tag: "Principal - Laulama Schools",
        //     content: 'By attending the Conference, I now have a better understanding of Laulama and what we have been doing this past year.',
        // },
        // {
        //     name: 'Student',
        //     tag: "Laulama Schools",
        //     content: 'I liked how organized it was.',
        // },
        {
            name: 'Jesse',
            tag: "Patient - Arya Psychiatry",
            content: 'I had a great experience here. Everything was timely, I felt understood and got the results. I was looking for. Very professional.',
        },
        // {
        //     name: 'Student',
        //     tag: "Laulama Schools",
        //     content: 'Gives instructions on how to do things and gives many options under a category.',
        // },
        // {
        //     name: 'Tayler Kanoho',
        //     tag: "Teacher - Laulama Schools",
        //     content: 'I now know what my issue was that caused the results for my class and what I need to change to improve.',
        // },
        // {
        //     name: 'Student',
        //     tag: "Laulama Schools",
        //     content: 'How easy it was to create an account.',
        // },
        {
            name: 'Chris',
            tag: "Patient - Arya Psychiatry",
            content: 'These are great guys. This service is a life saver for mentally ill people. Their Psychiatrists are top notch.',
        },
        // {
        //     name: 'Various',
        //     tag: "Principals - Laulama Schools",
        //     content: "These tools will change the way we plan for next year, we also can't wait for Laulama Learning.",
        // },
        // {
        //     name: 'Student',
        //     tag: "Laulama Schools",
        //     content: 'The best aspects of the Laulama platform was that it was easy to use and store information about my academics.',
        // },
        {
            name: "Prathyusha Allada",
            content: "The MasterClass for High Schoolers program was very well-organized program. This event helps high school students in boosting their academic performance and future achievement, and highschoolers actively engaged throughout. We eagerly await the next session and 8-week program, which will definitely be equally amazing."
        },
        {
            name: 'SS',
            tag: "Patient - Arya Psychiatry",
            content: 'Great doctors to work with.',
        },
        // {
        //     name: 'Corey Nakamura',
        //     tag: "Principal - Laulama Schools",
        //     content: 'The good thing is that what we learn at one school can be used everywhere, it eliminates duplication.',
        // },
        // {
        //     name: 'Student',
        //     tag: "Laulama Schools",
        //     content: 'It was very clear and easy to work with.',
        // },
        {
            name: "Arpita Ghosh",
            content: "The workshop by S2L Academy was an awesome experience for my daughter to understand the basic concepts of entrepreneurship and the exam prep was a very useful session.",
        },
        {
            name: 'GG',
            tag: "Patient - Arya Psychiatry",
            content: 'Dr Sunkara is very attentive and works well with our teen. I highly recommend this practice, and believe me when I say…',
        },
        // {
        //     name: 'Student',
        //     tag: "Laulama Schools",
        //     content: 'how easy it was to navigate and add information.',
        // },
        // {
        //     name: 'Leila Kobayashi',
        //     tag: "Principal - Laulama Schools",
        //     content: 'We have to continue for the sake of the schools staff.',
        // },
        // {
        //     name: 'Student',
        //     tag: "Laulama Schools",
        //     content: 'I liked that there were easy access options apart from selecting the courses. It was simple and straight forward like what I would expect.',
        // },
        {
            name: "Naren Babu Guntupalli",
            content: "Thanks for letting my son have the exposure to such a nice exercise. He was excited and has learned a lot in how to express ideas."
        }

    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 855,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <>
            <div className="section-heading m-4 pt-3">
                <h1 className='border-bottom-3'>Customer Testimonials</h1>
            </div>

            <div className='review-section-main h-full'>
                <div className="review-sec h-full">
                    <Slider {...settings}>
                        {reviews.map((d) => (
                            <div className=" m-2 p-3 carousel-card gap-6 h-full">
                                <div className='grid align-items-center justify-content-between p-2'>
                                    <div>
                                        <h2>{d.name}</h2>
                                    </div>
                                    <FontAwesomeIcon icon={faQuoteLeft} color='#352750' style={{ fontSize: '4rem' }} className='quotes-img' />
                                </div>
                                <p className="mt-0 mb-3" style={{ color: "#B7AFAF" }}>{d.tag}</p>

                                <p className="mt-0 mb-3">
                                    <Truncate className="mt-0 mb-3" content={d.content} />
                                </p>

                                <div className='gap-4 flex'>
                                    <FontAwesomeIcon icon={faStar} color='#8DAF61' style={{ fontSize: '1.3em' }} />
                                    <FontAwesomeIcon icon={faStar} color='#8DAF61' style={{ fontSize: '1.3em' }} />
                                    <FontAwesomeIcon icon={faStar} color='#8DAF61' style={{ fontSize: '1.3em' }} />
                                    <FontAwesomeIcon icon={faStar} color='#8DAF61' style={{ fontSize: '1.3em' }} />
                                    <FontAwesomeIcon icon={faStar} color='#8DAF61' style={{ fontSize: '1.3em' }} />
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>

            </div>
        </>

    )
}

export default Review;