import React, { useEffect, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Accordion, AccordionTab } from 'primereact/accordion';
import course1 from "../../../../Assets/AcademyInternship/friends-running-college-hall.png";
import course2 from "../../../../Assets/AcademyInternship/college-girls-studying-together (1).png";
import course3 from "../../../../Assets/AcademyInternship/colleagues-doing-team-work-project (1).png";
import course4 from "../../../../Assets/AcademyInternship/background-abstract-illustration-design-vector-pattern-futuristic-modern-shape-technology.png";
import course5 from "../../../../Assets/AcademyInternship/interns-collaborating-group-project.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import "../../Academy-StudentsProfile/Academy-Internship/Courses/Courses.scss";
import "./AcademyPlan.scss";
import { Button } from 'primereact/button';

const AcademyPlan = () => {

    const [, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const tab1HeaderTemplate = (options) => {
        const { onClick, className } = options;
        return (
            <li className={`${className} nn gap-3`} style={{ cursor: 'pointer' }} onClick={onClick}>
                <div style={{ width: "30px", height: "30px", backgroundColor: "#EF5064", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <span style={{ color: "white" }}>1</span>
                </div>
                <span className="">Orientation</span>
            </li>
        );
    };

    const tab2HeaderTemplate = (options) => {
        const { onClick, className } = options;
        return (
            <li className={`${className} nn gap-3`} style={{ cursor: 'pointer' }} onClick={onClick}>
                <div style={{ width: "30px", height: "30px", backgroundColor: "#8DAF61", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <span style={{ color: "white" }}>2</span>
                </div>
                <span className="">Bootcamp</span>
            </li>
        );
    };

    const tab3HeaderTemplate = (options) => {
        const { onClick, className } = options;
        return (
            <li className={`${className} nn gap-3`} style={{ cursor: 'pointer' }} onClick={onClick}>
                <div style={{ width: "30px", height: "30px", backgroundColor: "#F9AB60", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <span style={{ color: "white" }}>3</span>
                </div>
                <span className="">Masterclass</span>
            </li>
        );
    };

    const tab4HeaderTemplate = (options) => {
        const { onClick, className } = options;
        return (
            <li className={`${className} nn gap-3`} style={{ cursor: 'pointer' }} onClick={onClick}>
                <div style={{ width: "30px", height: "30px", backgroundColor: "#220D50", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <span style={{ color: "white" }}>4a</span>
                </div>
                <span className="">Ignite</span>
            </li>
        );
    };

    const tab5HeaderTemplate = (options) => {
        const { onClick, className } = options;
        return (
            <li className={`${className} nn gap-3`} style={{ cursor: 'pointer' }} onClick={onClick}>
                <div style={{ width: "30px", height: "30px", backgroundColor: "#4072D2", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <span style={{ color: "white" }}>4b</span>
                </div>
                <span className="">Apprenticeship</span>
            </li>
        );
    };

    const [activeIndex, setActiveIndex] = useState(1);
    const [activeIndexes, setActiveIndexes] = useState(1);

    const coursesData = {
        "workshops": {
            "header": "Orientation",
            "coursesDetails": [
                {
                    "step": "Step 1: Free Entrepreneurship Workshop for Students (1 Day)",
                    "content": " Students will have a foundational understanding of entrepreneurship and be motivated to pursue further learning.",
                    "objective": "Introduce students to the basics of entrepreneurship and ignite their interest in the subject.",
                    "components": [
                        {
                            "title": "Introduction to Entrepreneurship",
                            "description": "An entrepreneur's definition, importance, and key characteristics."
                        },
                        {
                            "title": "Ideation and Brainstorming",
                            "description": "Techniques for generating business ideas."
                        },
                        {
                            "title": "Basics of Business Models",
                            "description": "Understanding different types of business models."
                        },
                        {
                            "title": "Interactive Activities",
                            "description": "Group discussions, case studies, and idea pitches."
                        }
                    ]
                }
            ]
        },

        "courses": {
            "header": "Bootcamp",
            "coursesDetails": [
                {
                    "step": "Step 2: Intensive Entrepreneurship Boot Camp with Capstone Project (8 Weeks)",
                    "content": "Students will have practical knowledge of starting a business and will have completed a capstone project demonstrating their skills.",
                    "objective": "Provide in-depth knowledge and practical experience in entrepreneurship.",
                    "components": [
                        {
                            "title": "Week 1-2: Business Planning and Market Research",
                            "description": "Market analysis, identifying target customers, and competitive analysis."
                        },
                        {
                            "title": "Week 3-4: Product Development and Prototyping",
                            "description": "Developing a product/service, creating prototypes, and iterative testing."
                        },
                        {
                            "title": "Week 5-6: Marketing and Sales Strategies",
                            "description": "Branding, marketing channels, and sales tactics."
                        },
                        {
                            "title": "Week 7: Financial Literacy and Funding",
                            "description": "Basic accounting, budgeting, and understanding funding options."
                        },
                        {
                            "title": "Week 8: Capstone Project",
                            "description": "Students will form teams to create a business plan and pitch their startup idea to a panel of judges."
                        }
                    ]
                }
            ]
        },

        "masteryProgram": {
            "header": "Masterclass",
            "coursesDetails": [
                {
                    "step": "Step 3: Skills Masterclass (8 Weeks)",
                    "content": "Students will gain specialized knowledge and practical experience in their chosen field, enhancing their entrepreneurial toolkit.",
                    "objective": "Allow students to specialize in specific areas of entrepreneurship to deepen their expertise.",
                    "description": "Students will gain specialized knowledge and practical experience in their chosen field, enhancing their entrepreneurial toolkit.",
                    "keyComponents": [
                        "In-depth workshops and hands-on projects in the chosen specialization.",
                        "Guest speakers and mentors from the industry.",
                        "Final project presentation showcasing specialization skills."
                    ],
                    "specializationTracks": [
                        {
                            "title": "Digital Marketing and E-commerce",
                            "details": "SEO, social media marketing, content creation, and managing online stores."
                        },
                        {
                            "title": "Technology and Innovation",
                            "details": "Coding, app development, and emerging technologies like AI and blockchain."
                        },
                        {
                            "title": "Financial Management",
                            "details": "Advanced financial planning, investment strategies, and financial analysis."
                        },
                        {
                            "title": "Social Impact and Sustainability",
                            "details": "Developing businesses with a social impact focus, sustainable practices, and CSR."
                        }
                    ]
                }
            ]
        },

        "launchYourStartup": {
            "header": "Ignite",
            "coursesDetails": [
                {
                    "step": "Step 4a: Ignite Program (12 Weeks)",
                    "content": "Students will have launched their own startup with a clear business plan, operational model, and marketing strategy.",
                    "objective": "Guide students through the process of launching their own startup.",
                    "components": [
                        {
                            "title": "Business Model Refinement",
                            "description": "Fine-tuning business models based on feedback."
                        },
                        {
                            "title": "Legal and Regulatory Requirements",
                            "description": "Understanding the legal aspects of starting a business."
                        },
                        {
                            "title": "Product Development",
                            "description": "Finalizing product/service and preparing for launch."
                        },
                        {
                            "title": "Marketing and Sales",
                            "description": "Developing a go-to-market strategy and sales plan."
                        },
                        {
                            "title": "Funding and Financial Planning",
                            "description": "Securing funding and managing finances for growth."
                        },
                        {
                            "title": "Launch Preparation",
                            "description": "Setting up operations, customer support, and launching the startup."
                        }
                    ]
                }
            ]
        },

        "internship": {
            "header": "Apprenticeship",
            "coursesDetails": [
                {
                    "step": "Step 4b: Apprenticeship at Client Program (12 weeks)",
                    "content": "Students will gain invaluable hands-on experience, industry insights, and professional connections, enhancing their employability and entrepreneurial skills.",
                    "objective": "Provide real-world experience by interning at a startup or established company.",
                    "components": [
                        {
                            "title": "Tailored Placement",
                            "description": "Placement in a relevant company based on student interests and specialization."
                        },
                        {
                            "title": "Real-World Projects",
                            "description": "Working on real projects under the guidance of industry professionals."
                        },
                        {
                            "title": "Hands-On Experience",
                            "description": "Gaining practical experience and networking opportunities."
                        },
                        {
                            "title": "Continuous Feedback",
                            "description": "Regular feedback and performance evaluation."
                        }
                    ]
                }
            ]
        }
    }

    const onTabChange = (e) => {
        setActiveIndex(e.index);
    };

    const renderHeader = (title, index) => {
        return (
            <div>
                {title}
                <i className={`pi ${activeIndex === index ? 'pi-minus' : 'pi-plus'} accordion-icon`} style={{ float: 'right' }}></i>
            </div>
        );
    };

    return (
        <>
            <div className="academy-plan-section">
                <div className="academy-plan-section-header">
                    <h2>
                        Year-Long Career Growth Program for Middle and High School Students
                    </h2>
                </div>

                <div className="academy-plan-section-content">
                    <p>
                        The Startup2Launch Academy offers a comprehensive year-long program to
                        guide middle and high school students through a structured career
                        progression in entrepreneurship. The program is divided into four main
                        steps, each focusing on developing essential entrepreneurial skills,
                        from foundational knowledge to launching their own startup.
                        Additionally, the program offers an optional internship to provide
                        real-world experience.
                    </p>
                </div>
                <div className="courses-body academy-plan">
                    {window.innerWidth <= 1120 ? (<>

                        <Accordion activeIndex={activeIndex} onTabChange={onTabChange}>
                            <AccordionTab header={renderHeader(coursesData.workshops.header, 0)}>
                                {coursesData.workshops.coursesDetails.map((workshop, index) => (
                                    <div key={index} className="courses-details">
                                        <div className="course-about-section">
                                            <div className="course-heading">
                                                <h2>{workshop.step}</h2>
                                            </div>
                                            <div className="course-section-img-container">
                                                <img src={course1} alt="" />
                                            </div>
                                            <p>{workshop.content}</p>
                                            <div className="course-headings">
                                                <h2 className="m-0">Objective:</h2>
                                            </div>
                                            <p className="obj-content">{workshop.objective}</p>
                                            <div className="course-headings">
                                                <h2>Key Components:</h2>
                                            </div>

                                            {workshop.components.map((component, idx) => (
                                                <div key={idx} className="key-section">
                                                    <div className='step-1 col-1'><span className="step1-key"></span></div>
                                                    <div className="event-details">
                                                        <p><b>{component.title}: </b>
                                                            {component.description}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </AccordionTab>

                            <AccordionTab header={renderHeader(coursesData.courses.header, 1)}>
                                {coursesData.courses.coursesDetails.map((course, index) => (
                                    <div key={index} className="courses-details">
                                        <div className="course-about-section">
                                            <div className="course-heading">
                                                <h2>{course.step}</h2>
                                            </div>
                                            <div className="course-section-img-container">
                                                <img src={course2} alt="" />
                                            </div>
                                            <p>{course.content}</p>
                                            <div className="course-headings">
                                                <h2 className="m-0">Objective:</h2>
                                            </div>
                                            <p className="obj-content">{course.objective}</p>

                                            <Button className='purple-bg'><a href='https://courses.startup2launch.com/8-week-program' target="_blank" rel="noopener noreferrer">Enroll Now</a></Button>

                                            <div className="course-headings">
                                                <h2>Key Components:</h2>
                                            </div>
                                            {course.components.map((component, idx) => (
                                                <div key={idx} className="key-section">
                                                    <div className="event-icon">
                                                        <FontAwesomeIcon
                                                            icon={faCalendarDays}
                                                            className="calendar-icon"
                                                        />
                                                    </div>
                                                    <div className="event-details">
                                                        <p><b>{component.title}: </b>
                                                            {component.description}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </AccordionTab>

                            <AccordionTab header={renderHeader(coursesData.masteryProgram.header, 2)}>
                                {coursesData.masteryProgram.coursesDetails.map((course, index) => (
                                    <div key={index} className="courses-details">
                                        <div className="course-about-section">
                                            <div className="course-heading">
                                                <h2>{course.step}</h2>
                                            </div>
                                            <div className="course-section-img-container">
                                                <img src={course3} alt="Course" />
                                            </div>
                                            <p>{course.content}</p>
                                            <div className="course-headings">
                                                <h2 className="m-0">Objective:</h2>
                                            </div>
                                            <p className="obj-content">{course.objective}</p>
                                            <div className="course-headings">
                                                <h2>Key Components:</h2>
                                            </div>
                                            {course.keyComponents.map((component, idx) => (
                                                <div key={idx} className="key-section">
                                                    <ul className="event-details">
                                                        <li>{component}</li>
                                                    </ul>
                                                </div>
                                            ))}
                                            <div className="course-headings">
                                                <h2>Specialization Tracks:</h2>
                                            </div>
                                            {course.specializationTracks.map((track, idx) => (
                                                <div key={idx} className="key-section">
                                                    <div className='step-3 col-1'><span className="step3-key"></span></div>
                                                    <div className="event-details">
                                                        <p><b>{track.title}: </b>{track.details}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </AccordionTab>

                            <AccordionTab header={renderHeader(coursesData.launchYourStartup.header, 3)}>
                                {coursesData.launchYourStartup.coursesDetails.map((course, index) => (
                                    <div key={index} className="courses-details">
                                        <div className="course-about-section">
                                            <div className="course-heading">
                                                <h2>{course.step}</h2>
                                            </div>
                                            <div className="course-section-img-container">
                                                <img src={course4} alt="" />
                                            </div>
                                            <p>{course.content}</p>
                                            <div className="course-headings">
                                                <h2 className="m-0">Objective:</h2>
                                            </div>
                                            <p className="obj-content">{course.objective}</p>
                                            <div className="course-headings">
                                                <h2>Key Components:</h2>
                                            </div>
                                            {course.components.map((component, idx) => (
                                                <div key={idx} className="key-section">
                                                    <div className='step-4 col-2'><span className="step4-key"></span></div>
                                                    <div className="event-details">
                                                        <p><b>{component.title}: </b>
                                                            {component.description}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </AccordionTab>

                            <AccordionTab header={renderHeader(coursesData.internship.header, 4)}>
                                {coursesData.internship.coursesDetails.map((course, index) => (
                                    <div key={index} className="courses-details">
                                        <div className="course-about-section">
                                            <div className="course-heading">
                                                <h2>{course.step}</h2>
                                            </div>
                                            <div className="course-section-img-container">
                                                <img src={course5} alt="" />
                                            </div>
                                            <p>{course.content}</p>
                                            <div className="course-headings">
                                                <h2 className="m-0">Objective:</h2>
                                            </div>
                                            <p className="obj-content">{course.objective}</p>
                                            <div className="course-headings">
                                                <h2>Key Components:</h2>
                                            </div>
                                            {course.components.map((component, idx) => (
                                                <div key={idx} className="key-section">
                                                    <div className='step-4 col-2'><span className="step4-key"></span></div>
                                                    <div className="event-details">
                                                        <p><b>{component.title}: </b>
                                                            {component.description}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </AccordionTab>
                        </Accordion>

                    </>
                    ) : (
                        <TabView activeIndex={activeIndexes} onTabChange={(e) => { setActiveIndexes(e.index) }}>
                            <TabPanel header="Orientation" headerTemplate={tab1HeaderTemplate}>
                                <p className="m-0">
                                    <div className="courses-details">
                                        <div className="courses-details-main">
                                            <div className="courses-details-img">
                                                <div className="course-section-img-container">
                                                    <img src={course1} />
                                                </div>
                                            </div>
                                            <div className="course-about-section">
                                                <div className="course-heading">
                                                    <h2>Step 1: Free Entrepreneurship Workshop for Students (1 Day)</h2>
                                                </div>

                                                <div className="course-content">
                                                    <p>
                                                        Students will have a foundational understanding of entrepreneurship
                                                        and be motivated to pursue further learning.
                                                    </p>
                                                </div>
                                                <div className="course-heading">
                                                    <h2 className="m-0">Objective:</h2>
                                                </div>
                                                <div className="course-content">
                                                    <p className="obj-content"> Introduce students to the basics of entrepreneurship and ignite their interest in the subject.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="courses-details-sub-section">
                                            <div className="course-heading">
                                                <h2>Key Components:</h2>
                                            </div>
                                            <div className="key-section">
                                                <div className="col key-section-content ">
                                                    <div className="key-details-container col-6 ">
                                                        <div className='step-1 col-2'><span className="step1-key"></span></div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p> Introduction to Entrepreneurship</p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p>
                                                                    An entrepreneur's definition, importance, and key characteristics.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="key-details-container col-6">
                                                        <div className='step-1 col-2'><span className="step1-key"></span></div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p>Ideation and Brainstorming</p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p>Techniques for generating business ideas.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="key-section">
                                                <div className="col key-section-content">
                                                    <div className="key-details-container col-6 ">
                                                        <div className='step-1 col-2'><span className="step1-key"></span></div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p>Basics of Business Models</p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p>Understanding different types of business models.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="key-details-container col-6 ">
                                                        <div className='step-1 col-2'><span className="step1-key"></span></div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p>Interactive Activities</p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p>Group discussions, case studies, and idea pitches.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p>
                            </TabPanel>

                            <TabPanel header="Bootcamp" headerTemplate={tab2HeaderTemplate}>
                                <p className="m-0">
                                    <div className="courses-details">
                                        <div className="courses-details-main">
                                            <div className="courses-details-img">
                                                <div className="course-section-img-container">
                                                    <img src={course2} />
                                                </div>
                                            </div>
                                            <div className="course-about-section">
                                                <div className="course-heading">
                                                    <h2>Step 2: Intensive Entrepreneurship Boot Camp with Capstone Project (8 Weeks)</h2>
                                                </div>

                                                <div className="course-content">
                                                    <p>
                                                        Students will have practical knowledge of starting a business
                                                        and will have completed a capstone project demonstrating their
                                                        skills.
                                                    </p>
                                                </div>
                                                <div className="course-heading">
                                                    <h2 className="m-0">Objective:</h2>
                                                </div>
                                                <div className="course-content">
                                                    <p className="obj-content">
                                                        Provide in-depth knowledge and practical experience in
                                                        entrepreneurship.
                                                    </p>
                                                </div>
                                                <Button className='purple-bg'><a href='https://courses.startup2launch.com/8-week-program' target="_blank" rel="noopener noreferrer">Enroll Now</a></Button>
                                            </div>
                                        </div>
                                        <div className="courses-details-sub-section">
                                            <div className="course-heading">
                                                <h2>Key Components:</h2>
                                            </div>
                                            <div className="key-section">
                                                <div className="col key-section-content ">
                                                    <div className="key-details-container col-6 ">
                                                        <div className="event-icon">
                                                            <FontAwesomeIcon
                                                                icon={faCalendarDays}
                                                                className="calendar-icon"
                                                            />
                                                        </div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p> Week 1-2: Business Planning and Market Research</p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p>
                                                                    Market analysis, identifying target customers, and
                                                                    competitive analysis.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="key-details-container col-6">
                                                        <div className="event-icon">
                                                            <FontAwesomeIcon
                                                                icon={faCalendarDays}
                                                                className="calendar-icon"
                                                            />
                                                        </div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p>
                                                                    Week 3-4: Product Development and Prototyping
                                                                </p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p>
                                                                    Developing a product/service, creating prototypes, and
                                                                    iterative testing.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="key-section">
                                                <div className="col key-section-content">
                                                    <div className="key-details-container col-6 ">
                                                        <div className="event-icon">
                                                            <FontAwesomeIcon
                                                                icon={faCalendarDays}
                                                                className="calendar-icon"
                                                            />
                                                        </div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p>
                                                                    Week 5-6: Marketing and Sales Strategies
                                                                </p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p>
                                                                    Branding, marketing channels, and sales tactics.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="key-details-container col-6 ">
                                                        <div className="event-icon">
                                                            <FontAwesomeIcon
                                                                icon={faCalendarDays}
                                                                className="calendar-icon"
                                                            />
                                                        </div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p>
                                                                    Week 7: Financial Literacy and Funding
                                                                </p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p>
                                                                    Basic accounting, budgeting, and understanding funding
                                                                    options.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="key-section">
                                                <div className="col key-section-content ">
                                                    <div className="key-details-container col-6 ">
                                                        <div className="event-icon">
                                                            <FontAwesomeIcon
                                                                icon={faCalendarDays}
                                                                className="calendar-icon"
                                                            />
                                                        </div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p>
                                                                    Week 8: Capstone Project
                                                                </p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p>
                                                                    Students will form teams to create a business plan and
                                                                    pitch their startup idea to a panel of judges.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p>
                            </TabPanel>

                            <TabPanel header="Masterclass" headerTemplate={tab3HeaderTemplate}>
                                <p className="m-0">
                                    <div className="courses-details">
                                        <div className="courses-details-main">
                                            <div className="courses-details-img">
                                                <div className="course-section-img-container">
                                                    <img src={course3} />
                                                </div>
                                            </div>
                                            <div className="course-about-section">
                                                <div className="course-heading">
                                                    <h2>
                                                        Step 3: Skills Master (8 Weeks)
                                                    </h2>
                                                </div>

                                                <div className="course-content">
                                                    <p>
                                                        Students will gain specialized knowledge and practical
                                                        experience in their chosen field, enhancing their entrepreneurial toolkit.
                                                    </p>
                                                </div>
                                                <div className="course-heading">
                                                    <h2 className="m-0">Objective:</h2>
                                                </div>
                                                <div className="course-content">
                                                    <p className="obj-content">
                                                        Allow students to specialize in specific areas of entrepreneurship to deepen their expertise.
                                                    </p>
                                                </div>
                                                <div className="course-heading">
                                                    <h2 className="mb-0">
                                                        Key Components:
                                                    </h2>
                                                </div>
                                                <div className="course-content">
                                                    <ul className="obj-content">
                                                        <li>In-depth workshops and hands-on projects in the chosen specialization.</li>
                                                        <li>Guest speakers and mentors from the industry.</li>
                                                        <li>Final project presentation showcasing specialization skills.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="courses-details-sub-section">
                                            <div className="course-heading">
                                                <h2>Specialization Tracks:</h2>
                                            </div>
                                            <div className="key-section">
                                                <div className="col key-section-content ">
                                                    <div className="key-details-container col-6 ">
                                                        <div className='step-3 col-2'><span className="step3-key"></span></div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p>
                                                                    Digital Marketing and E-commerce
                                                                </p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p>
                                                                    SEO, social media marketing, content creation, and managing online stores.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="key-details-container col-6">
                                                        <div className='step-3 col-2'><span className="step3-key"></span></div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p>
                                                                    Technology and Innovation
                                                                </p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p>
                                                                    Coding, app development, and emerging technologies like AI and blockchain
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="key-section">
                                                <div className="col key-section-content">
                                                    <div className="key-details-container col-6 ">
                                                        <div className='step-3 col-2'><span className="step3-key"></span></div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p>
                                                                    Financial Management
                                                                </p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p>
                                                                    Advanced financial planning, investment strategies, and financial analysis.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="key-details-container col-6 ">
                                                        <div className='step-3 col-2'><span className="step3-key"></span></div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p>
                                                                    Advanced financial planning, investment strategies, and financial analysis.
                                                                </p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p>
                                                                    Developing businesses with a social impact focus, sustainable practices, and CSR.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p>
                            </TabPanel>

                            <TabPanel header="Ignite" headerTemplate={tab4HeaderTemplate}>
                                <p className="m-0">
                                    <div className="courses-details">
                                        <div className="courses-details-main">
                                            <div className="courses-details-img">
                                                <div className="course-section-img-container">
                                                    <img src={course4} />
                                                </div>
                                            </div>
                                            <div className="course-about-section">
                                                <div className="course-heading">
                                                    <h2>
                                                        Step 4a: Ignite Program (12 Weeks)
                                                    </h2>
                                                </div>

                                                <div className="course-content">
                                                    <p>
                                                        Students will have launched their own startup with a clear business plan,
                                                        operational model, and marketing strategy.
                                                    </p>
                                                </div>
                                                <div className="course-heading">
                                                    <h2 className="m-0">Objective: </h2>
                                                </div>
                                                <div className="course-content">
                                                    <p className="obj-content">
                                                        Guide students through the process of launching their own startup.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="courses-details-sub-section">
                                            <div className="course-heading">
                                                <h2>Key Components:</h2>
                                            </div>
                                            <div className="key-section">
                                                <div className="col key-section-content ">
                                                    <div className="key-details-container col-6 ">
                                                        <div className='step-4 col-2'><span className="step4-key"></span></div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p>
                                                                    Business Model Refinement
                                                                </p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p>
                                                                    Fine-tuning business models based on feedback.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="key-details-container col-6">
                                                        <div className='step-4 col-2'><span className="step4-key"></span></div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p>
                                                                    Legal and Regulatory Requirements
                                                                </p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p>
                                                                    Understanding the legal aspects of starting a business.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="key-section">
                                                <div className="col key-section-content">
                                                    <div className="key-details-container col-6 ">
                                                        <div className='step-4 col-2'><span className="step4-key"></span></div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p>
                                                                    Product Development
                                                                </p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p>
                                                                    Finalizing product/service and preparing for launch.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="key-details-container col-6 ">
                                                        <div className='step-4 col-2'><span className="step4-key"></span></div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p>
                                                                    Marketing and Sales
                                                                </p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p>
                                                                    Developing a go-to-market strategy and sales plan.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="key-section">
                                                <div className="col key-section-content">
                                                    <div className="key-details-container col-6 ">
                                                        <div className='step-4 col-2'><span className="step4-key"></span></div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p>
                                                                    Funding and Financial Planning
                                                                </p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p>
                                                                    Securing funding and managing finances for growth.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="key-details-container col-6 ">
                                                        <div className='step-4 col-2'><span className="step4-key"></span></div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p>
                                                                    Launch Preparation
                                                                </p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p>
                                                                    Setting up operations, customer support, and launching the startup.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p>
                            </TabPanel>

                            <TabPanel header="Apprenticeship" headerTemplate={tab5HeaderTemplate}>
                                <p className="m-0">
                                    <div className="courses-details">
                                        <div className="courses-details-main">
                                            <div className="courses-details-img">
                                                <div className="course-section-img-container">
                                                    <img src={course5} />
                                                </div>
                                            </div>
                                            <div className="course-about-section">
                                                <div className="course-heading">
                                                    <h2>Step 4b: Apprenticeship at Client Program (12 weeks)</h2>
                                                </div>

                                                <div className="course-content">
                                                    <p>
                                                        Students will gain invaluable hands-on experience, industry insights,
                                                        and professional connections, enhancing their employability and entrepreneurial skills.
                                                    </p>
                                                </div>
                                                <div className="course-heading">
                                                    <h2 className="m-0">Objective:</h2>
                                                </div>
                                                <div className="course-content">
                                                    <p className="obj-content">
                                                        Provide real-world experience by interning at a startup or established company.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="courses-details-sub-section">
                                            <div className="course-heading">
                                                <h2>Key Components:</h2>
                                            </div>
                                            <div className="key-section">
                                                <div className="col key-section-content ">
                                                    <div className="key-details-container col-6 ">
                                                        <div className='step-5 col-2'><span className="step5-key"></span></div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p>Tailored Placement</p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p> Placement in a relevant company based on student interests and specialization.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="key-details-container col-6">
                                                        <div className='step-5 col-2'><span className="step5-key"></span></div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p>Real-World Projects</p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p>Working on real projects under the guidance of industry professionals.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="key-section">
                                                <div className="col key-section-content">
                                                    <div className="key-details-container col-6 ">
                                                        <div className='step-5 col-2'><span className="step5-key"></span></div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p>Hands-On Experience</p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p>Gaining practical experience and networking opportunities.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="key-details-container col-6 ">
                                                        <div className='step-5 col-2'><span className="step5-key"></span></div>
                                                        <div className="event-details">
                                                            <div className="event-duration">
                                                                <p>Continuous Feedback</p>
                                                            </div>
                                                            <div className="event-progress">
                                                                <p>Regular feedback and performance evaluation.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p>
                            </TabPanel>
                        </TabView>
                    )}
                </div>
            </div>
        </>
    )
}

export default AcademyPlan