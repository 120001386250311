import React from 'react';
import Header from './Header/Header';
import BenefitsToClients from './BenefitsToClients/BenefitsToClients';
import HomeBanner from './HomeBanner/HomeBanner';
import Services from './Services/Services';
import Footer from './Footer/Footer';
import OurProjects from './OurProjects/OurProjects';
import Review from './ReviewSection/Review';
import HowItWorks from './HowItWorks/HowItWorks';
import { ScrollTop } from 'primereact/scrolltop';
import BenefitfromS2L from './Benefit-from-S2L/BenefitfromS2L';
import Startupservices from './Startupservices/Startupservices';
// import ChatBot from '../../Shared-Components/Chat-Bot/ChatBot';
import AdBanner from './AdBanner/AdBanner';

const Home = () => {

    return (
        <div>
            <ScrollTop />
            <Header />
            {/* <AdBanner /> */}
            <HomeBanner />
            {/* <BenefitsToClients /> */}
            <HowItWorks />
            <Services />
            <OurProjects />
            <BenefitfromS2L />
            <Review />
            <Footer />
            {/* <Startupservices/> */}
            {/* <ChatBot /> */}
        </div>
    )
}

export default Home