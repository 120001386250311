import React from "react";
import "./SpeakerSection.scss";
import Phani from "../../../../../Assets/Workshops/Workshop1/Speaker.png";
import Sumi from "../../../../../Assets/Workshops/Workshop2/Speaker.png";

const SpeakerSection = () => {
  const userData = [
    {
      id: 1,
      profile: Phani,
      name: "Phani Ilapakurty",
      position: "Partner",
      company: "Cherry Bracket",
    },
    {
      id: 2,
      profile: Sumi,
      name: "Sumi Yi",
      position: "College Counselor",
      company: "C2Educate",
    },
  ];
  return (
    <div id="speaker-section" className="speaker">
      <div className="speaker-section-main">
        <div className="speaker-header flex flex-column ">
          <h2 className="speaker-title font-bold">Speakers/ Mentors</h2>
          <p className="speaker-description mt-0 mb-0">
            Add a short description of your speakers here. Why should your
            audience hear from them? Is there a theme they ll all be covering in
            their talks? What makes them unique? Use this space to give the
            reader a sense of why these are the speakers you want to showcase.
          </p>
        </div>
        <div className="speaker-profile flex flex-row  align-items-center justify-content-center gap-4 mt-6">
          {userData?.map((user, index) => (
            <div className="user-profile-details" key={user.id}>
              <div className="user-profile-content">
                <div className="speaker-profile-img">
                  <img src={user.profile} alt="user-profile-pic" />
                </div>
                <div className="profile-details flex flex-column ">
                  <span className="user-name">{user.name}</span>
                  <span className="user-position">{user.position}</span>
                  <span className="user-company">{user.company}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SpeakerSection;
