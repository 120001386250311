import React, { useRef, useState } from 'react';

import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { FileUpload } from 'primereact/fileupload';
import { InputMask } from 'primereact/inputmask';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { FaCheckCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';


const Form = ({ handleFormClose }) => {

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [pdfData, setPdfData] = useState("");
    const fileUploadRef = useRef(null);
    const [date, setDate] = useState(null);

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        parentFullName: '',
        parentEmail: '',
        parentPhoneNumber: '',
        address: '',
        city: '',
        zipcode: '',
        state: '',
        studentFirstName: '',
        studentLastName: '',
        studentEmail: '',
        studentPhoneNumber: '',
        grade: '',
        schoolName: '',
        specialInterests: '',
        otherDetails: '',
        paymentMethod: '',
        terms: '',
        programList: '',
        agreement: '',
        date: '',

    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const onSelect = (event) => {
        const file = event.files[0];
        const fileName = file.name;
        console.log(fileName);
        const fileExtension = fileName.split('.').pop().toLowerCase();
        const reader = new FileReader();

        reader.onloadend = () => {
            setPdfData(reader.result);

            setFormData({
                ...formData,
                fileExtension: fileExtension,
            });
        };

        reader.readAsDataURL(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log(formData);

        if (!formData.terms) {
            alert('You must agree to the Terms and Conditions before submitting the form.');
            return;
        }

        if (!pdfData) {
            alert('Please Upload Your Resume.');
            return;
        }

        const payload = {
            ...formData,
            base64Data: pdfData,
            fileExtension: formData.fileExtension,
        };

        try {
            const response = fetch(
                "https://nxqrlk3510.execute-api.us-east-1.amazonaws.com/sendEmail",
                {
                    method: "POST",
                    mode: "no-cors",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(payload),
                }
            );

            if (!response.ok) {
                setShowSuccessMessage(true);

                setTimeout(() => {
                    setShowSuccessMessage(false);
                    handleFormClose();
                    setFormData({
                        parentFullName: '',
                        parentEmail: '',
                        parentPhoneNumber: '',
                        address: '',
                        city: '',
                        zipcode: '',
                        state: '',
                        studentFirstName: '',
                        studentLastName: '',
                        studentEmail: '',
                        studentPhoneNumber: '',
                        grade: '',
                        schoolName: '',
                        specialInterests: '',
                        otherDetails: '',
                        paymentMethod: '',
                        terms: '',
                        programList: '',
                        agreement: '',
                        date: '',
                    });
                    setDate(null);
                }, 3000);
            }

            if (!response.ok) {
                const errorText = response.text();
                console.error("HTTP Error: ", response.status, errorText);
                throw new Error(`HTTP Error: ${response.status} ${errorText}`);
            }
        } catch (error) {
            console.error("Error during fetch: ", error);
        }
    };

    const handlePaymentMethodChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value === "true" ? true : value,
        });

        // if (value === 'Zelle') {
        //     window.location.href = 'https://zellepay.com'; // Replace with actual Zelle payment URL
        // }
    };

    const handleDateChange = (e) => {
        const selectedDate = e.value;
        setDate(selectedDate);

        setFormData({
            ...formData,
            date: selectedDate,
        });
    };


    const program = [
        { name: '8-week Intensive Entrepreneurship Program', code: '8Week' },
    ];

    const data = [
        {
            id: 1,
            title: 'Program Overview',
            items: [
                { id: 'a', content: 'The 8-Week Entrepreneurship Program is designed for middle and high school students (grades 6-12) to learn fundamental business skills, including ideation, product development, marketing, and financial planning.' },
            ],
        },
        {
            id: 2,
            title: 'Eligibility',
            items: [
                { id: 'a', content: 'The program is open to students currently enrolled in middle or high school (grades 6-12).' },
                { id: 'b', content: 'Participants must have parental or guardian consent to register.' },
            ],
        },
        {
            id: 3,
            title: 'Registration and Payment',
            items: [
                {
                    id: 'a',
                    subheader: 'Registration:',
                    content: 'All participants must complete the registration form, providing accurate and up-to-date information.',
                },
                {
                    id: 'b',
                    subheader: 'Payment:',
                    content: 'The program fee is $1000 for eight weeks and can be paid $500/month. Payment must be made in full via Zelle at the time of registration. Instructions for payment will be provided upon registration completion.'
                },
                {
                    id: 'c',
                    subheader: 'Late Payment:',
                    content: 'Registrations are only confirmed once full payment is received. If payment is not received within 5 days of registration or before 5th class for the second month, the spot may be forfeited.'
                }
            ],
        },
        {
            id: 4,
            title: 'Refund and Cancellation Policy',
            items: [
                {
                    id: 'a',
                    subheader: 'Cancellation by Participant:',
                    content: 'If a participant withdraws from the program:',
                    subItem: [
                        { id: 'i', subContent: 'Before 14 days of the program start date: A full refund will be issued minus a processing fee of $50.' },
                        { id: 'ii', subContent: 'Within 14 days of the program start date: A 50% refund will be issued.' },
                        { id: 'iii', subContent: 'After the program start date: No refunds will be provided.' }
                    ]
                },
                {
                    id: 'b',
                    subheader: 'Cancellation by Startup2Launch:',
                    content: 'If the program is canceled by Startup2Launch due to unforeseen circumstances, participants will receive a full refund.'
                }
            ]
        },
        {
            id: 5,
            title: 'Attendance and Conduct',
            items: [
                {
                    id: 'a',
                    subheader: 'Attendance',
                    content: 'Students are expected to attend all scheduled sessions. If a student cannot attend a session, prior notice should be given.'
                },
                {
                    id: 'b',
                    subheader: 'Conduct',
                    content: 'All participants are expected to behave respectfully toward instructors and peers. Disruptive or inappropriate behavior may result in removal from the program without a refund.'
                },
                {
                    id: 'c',
                    subheader: 'Dress Code',
                    content: 'Students should dress appropriately for an educational environment. Clothing should be respectful and not distracting to the learning environment.'
                }
            ]
        },
        {
            id: 6,
            title: 'Program Content and Intellectual Property',
            items: [
                { id: 'a', content: 'All course materials provided during the program are the intellectual property of Startup2Launch and are intended for personal educational use only. Materials may not be reproduced, distributed, or shared without written consent from Startup2Launch.' }
            ]
        },
        {
            id: 7,
            title: 'Use of Personal Information',
            items: [
                { id: 'a', content: "By registering for the program, you consent to the collection and use of your personal information in accordance with Startup2Launch's Privacy Policy. Personal information will be used solely for program administration and communication purposes." }
            ]
        },
        {
            id: 8,
            title: 'Liability Waiver',
            items: [
                { id: 'a', content: 'Startup2Launch is not responsible for any personal injury, loss, or damage that may occur during the program. Parents/Guardians agree to waive any claims against Startup2Launch, its instructors, and affiliates.' }
            ]
        },
        {
            id: 9,
            title: 'Media Release',
            items: [
                { id: 'a', content: 'By participating in the program, you grant Startup2Launch permission to use photographs, videos, or audio recordings of your child for promotional and educational purposes. If you do not consent, please notify us in writing at the time of registration.' }
            ]
        },
        {
            id: 10,
            title: 'Amendments to Terms and Conditions',
            items: [
                { id: 'a', content: 'Startup2Launch reserves the right to modify these terms and conditions at any time. Any changes will be communicated to participants and posted on our website.' }
            ]
        },
        {
            id: 11,
            title: 'Contact Information',
            items: [
                {
                    id: 'a',
                    content: 'For any questions or concerns regarding these terms and conditions, please get in touch with us at:',
                    email: 'abha.joshi@startup2launch.com',
                    phone: '(214)-766-0767'
                }
            ]
        }
    ];

    const ListItem = ({ item }) => (
        <li>
            {item.id}. <u>{item.subheader}</u> {item.content}
            {item.email && (
                <>
                    <br />
                    <u>Email:</u> {item.email}
                </>
            )}

            {item.phone && (
                <>
                    <br />
                    <u>Phone:</u> {item.phone}
                </>
            )}

            {item.subItem && (
                <ul>
                    {item.subItem.map((subItem) => (
                        <li key={subItem.id}>
                            {subItem.id}. {subItem.subContent}
                        </li>
                    ))}
                </ul>
            )}
        </li>
    );

    const List = ({ data }) => (
        <ul>
            {data.map((section) => (
                <li key={section.id}>
                    <b>{section.id}. {section.title}</b>
                    <ul>
                        {section.items.map((item) => (
                            <ListItem key={item.id} item={item} />
                        ))}
                    </ul>
                </li>
            ))}
        </ul>
    );

    const handleRedirect = () => {
        navigate("/s2lAcademy");
    };

    return (
        <>
            {!showSuccessMessage ?
                (
                    <div>
                        <div className="enroll-form-section">
                            <div className="header">
                                <h2>About the Program</h2>
                            </div>
                            <div className="content">
                                <p>Reserve your Spot! Complete the form below to confirm your enrollment for Masterclass for Middle & High Schoolers</p>

                                <ul>
                                    <li>Where: Flower Mount Library</li>
                                    <li>Address: 3030 Broadmoor Lane, Flower Mound, TX 75022</li>
                                    <li>When: Saturday, July 27 · 11:30 AM to 2:30 PM (Arrive 15 minutes early for Check In)</li>
                                    <li>Parents will get an opportunity to learn about S2L offerings with a detailed overview of the program at 11.30 pm</li>
                                    <li>Device: Don't forget to bring your phone or tablet to participate in our interactive activities.</li>
                                    <li>Free Lunch, Snacks, and Drinks Included!</li>
                                    <li>Please fill out separate forms for multiple participants</li>
                                </ul>
                            </div>
                        </div>
                        <div className="form-section">
                            <h2 className=" text-center">Form</h2>


                            <form onSubmit={handleSubmit}>
                                <div className='form-sec'>
                                    <p>Fill up the form with details required to join this workshop. </p>

                                    <h3>1. Parent Information:</h3>

                                    <div className="field col-12 flex flex-column gap-2 p-0">
                                        <label htmlFor="parentFullName">Parent Full Name*</label>
                                        <InputText id="parentFullName" type="text"
                                            name="parentFullName"
                                            value={formData.parentFullName}
                                            onChange={handleChange}
                                            placeholder='Parent Full Name'
                                            required
                                        />
                                    </div>

                                    <div className="field col-12 flex flex-column gap-2 p-0">
                                        <label htmlFor="parentEmail">Parent's Email Address*</label>
                                        <InputText id="parentEmail" type="email"
                                            name="parentEmail"
                                            value={formData.parentEmail}
                                            onChange={handleChange}
                                            placeholder='Parent Email'
                                            required
                                        />
                                    </div>

                                    <div className="field col-12 flex flex-column gap-2 p-0">
                                        <label htmlFor="parentPhoneNumber">Parent's Mobile Number*</label>
                                        <InputMask id="parentPhoneNumber" name="parentPhoneNumber"
                                            mask="(999) 999-9999"
                                            value={formData.parentPhoneNumber}
                                            onChange={handleChange}
                                            placeholder="(999) 999-9999"
                                            required
                                        />
                                    </div>

                                    <div className="field col-12 flex flex-column gap-2 p-0">
                                        <label htmlFor="address">Street Address*</label>
                                        <InputText id="address" type="text"
                                            name="address"
                                            value={formData.address}
                                            onChange={handleChange}
                                            placeholder='Street Address'
                                            required
                                        />
                                    </div>

                                    <div className="field col-12 flex flex-column gap-2 p-0">
                                        <label htmlFor="city">City*</label>
                                        <InputText id="city" type="text"
                                            name="city"
                                            value={formData.city}
                                            onChange={handleChange}
                                            placeholder='City'
                                            required
                                        />
                                    </div>

                                    <div className="field col-12 flex flex-column gap-2 p-0">
                                        <label htmlFor="zipcode">Zipcode/Pincode*</label>
                                        <InputText id="zipcode" type="number"
                                            name="zipcode"
                                            value={formData.zipcode}
                                            onChange={handleChange}
                                            placeholder='Zipcode/Pincode'
                                            required
                                        />
                                    </div>

                                    <div className="field col-12 flex flex-column gap-2 p-0">
                                        <label htmlFor="state">State/Region*</label>
                                        <InputText id="state" type="text"
                                            name="state"
                                            value={formData.state}
                                            onChange={handleChange}
                                            placeholder='State/Region'
                                            required
                                        />
                                    </div>

                                    <h3>2. Student Information:</h3>

                                    <div className="formgrid grid">
                                        <div className="field col flex flex-column">
                                            <label htmlFor="studentFirstName">Student's First Name*</label>
                                            <InputText id="studentFirstName" type="text"
                                                name="studentFirstName"
                                                value={formData.studentFirstName}
                                                onChange={handleChange}
                                                placeholder='First Name'
                                                required
                                            />
                                        </div>
                                        <div className="field col flex flex-column">
                                            <label htmlFor="studentLastName">Student's Last Name*</label>
                                            <InputText id="studentLastName" type="text"
                                                name="studentLastName"
                                                value={formData.studentLastName}
                                                onChange={handleChange}
                                                placeholder='Last Name'
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="field col-12 flex flex-column gap-2 p-0">
                                        <label htmlFor="studentEmail">Student's Email Address*</label>
                                        <InputText id="studentEmail" type="email"
                                            name="studentEmail"
                                            value={formData.studentEmail}
                                            onChange={handleChange}
                                            placeholder='Student Email'
                                            required
                                        />
                                    </div>

                                    <div className="field col-12 flex flex-column gap-2 p-0">
                                        <label htmlFor="studentPhoneNumber">Student's Mobile Number*</label>
                                        <InputMask id="studentPhoneNumber" name="studentPhoneNumber"
                                            mask="(999) 999-9999"
                                            value={formData.studentPhoneNumber}
                                            onChange={handleChange}
                                            placeholder="(999) 999-9999"
                                            required
                                        />
                                    </div>

                                    <div className="field col-12 flex flex-column gap-2 p-0">
                                        <label htmlFor="grade">Grade*</label>
                                        <InputText id="grade" type="text"
                                            name="grade"
                                            value={formData.grade}
                                            onChange={handleChange}
                                            placeholder='Grade'
                                            required
                                        />
                                    </div>

                                    <div className="field col-12 flex flex-column gap-2 p-0">
                                        <label htmlFor="schoolName">School Name*</label>
                                        <InputText id="schoolName" type="text"
                                            name="schoolName"
                                            value={formData.schoolName}
                                            onChange={handleChange}
                                            placeholder='School Name'
                                            required
                                        />
                                    </div>

                                    <div className="field col-12 flex flex-column gap-2 p-0">
                                        <label htmlFor="specialInterests">Special Interests or Skills*</label>
                                        <InputText id="specialInterests" type="text"
                                            name="specialInterests"
                                            value={formData.specialInterests}
                                            onChange={handleChange}
                                            placeholder='Special Interests or Skills'
                                            required
                                        />
                                    </div>

                                    <div className="field col-12 flex flex-column gap-2 p-0">
                                        <label htmlFor="otherDetails">Others (Please Specify)</label>
                                        <InputTextarea id="otherDetails" name="otherDetails"
                                            rows={6}
                                            value={formData.otherDetails}
                                            onChange={handleChange}
                                            placeholder="Write here..."
                                        />
                                    </div>

                                    <h3>3. Program Information:</h3>
                                    <p>8-week Intensive Entrepreneurship Program starts: September 22, 2024</p>

                                    <div className="field col-12 flex flex-column gap-2 p-0">
                                        <label htmlFor="programList">Program</label>
                                        <Dropdown id="programList" name="programList"
                                            value={formData.programList}
                                            options={program}
                                            onChange={handleChange}
                                            optionLabel="name"
                                            placeholder="Select a Program"
                                            className="w-full"
                                            required
                                        />
                                    </div>

                                    <h3>4. Payment Information:</h3>
                                    <span>Payment Method*</span>
                                    <br />
                                    <div className="field-radiobutton mt-3">
                                        <RadioButton inputId="zelle" name="paymentMethod" value="Zelle"
                                            onChange={handlePaymentMethodChange}
                                            checked={formData.paymentMethod === 'Zelle'}
                                        />

                                        <label htmlFor="zelle">Zelle to Venket.Kandanala@startup2launch.com</label>

                                    </div>

                                    <div>
                                        <label htmlFor="attachScreenshot*">Attach screenshot*</label>

                                        <FileUpload
                                            ref={fileUploadRef}
                                            mode="basic"
                                            name="demo[]"
                                            accept=".png"
                                            maxFileSize={10000000}
                                            onSelect={onSelect}
                                            chooseLabel="Add screenshot of payment"
                                            value={formData.base64Data}
                                            required
                                        />
                                    </div>

                                    <h3>5. Agreement to Terms and Conditions:</h3>

                                    <p>
                                        Read the things to keep in mind below along with the terms and conditions.
                                        Confirm the agreement before completing the signup process.
                                    </p>

                                </div>

                                <div className="tc-section">
                                    <div className="header">
                                        <h2>Things to keep in mind</h2>
                                    </div>

                                    <div className="content">
                                        <p>Startup2Launch 8-Week Program: Terms and Conditions</p>

                                        <p><b>Effective Date:</b> [August 28th, 2024]</p>
                                        <p><b>Last Updated:</b> [August 28th, 2024]</p>

                                        <p>
                                            <b>Welcome to the Startup2Launch 8-Week Entrepreneurship Program!</b>
                                            <br />
                                            Please read the following terms and conditions carefully before registering
                                            for the program. By enrolling, you (the parent/guardian and student) agree to
                                            comply with and be bound by these terms.
                                        </p>

                                        <List data={data} />

                                        By enrolling in the Startup2Launch 8-Week Entrepreneurship Program,
                                        you acknowledge that you have read, understood, and agree to these terms and conditions.

                                        <div className="field-checkbox">
                                            <RadioButton
                                                inputId="terms"
                                                name="terms"
                                                value={true}
                                                checked={formData.terms === true}
                                                onChange={handlePaymentMethodChange}
                                                required
                                            />
                                            <label htmlFor="terms">I have read the above terms and conditions and as a parent/ legal guardian * <br />
                                                I consent to my child participating in the S2L Academy Workshop and agree to the terms and conditions.</label>
                                        </div>

                                        <div className="field-checkbox">
                                            <RadioButton
                                                inputId="agreement"
                                                name="agreement"
                                                value={true}
                                                checked={formData.agreement === true}
                                                onChange={handlePaymentMethodChange}
                                                required
                                            />
                                            <label htmlFor="agreement">I agree that my child's photographs of engaging activities and interacting with mentors and peers may be used to promote the Startup2Launch Academy programs.*</label>
                                        </div>

                                        <div className="field">
                                            <label htmlFor="date">Today's Date</label>
                                            <Calendar value={date} onChange={handleDateChange} required className="w-full" />
                                        </div>

                                        <p>By clicking submit, I agree to the <a href="https://share.hsforms.com/1y1YZOmGnSVSrPKzZLAiLvwqkr5q" target='_blank' rel="noreferrer">Terms and Conditions</a>.</p>

                                        <div className="form-actions text-center">
                                            <Button type="submit" className="p-mt-2">Submit</Button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                ) : (
                    <div className="success-messages">
                        <div className="success-message-contents">
                            <FaCheckCircle className="check-icon"></FaCheckCircle>
                            <div className="success-message-headings">
                                Thank you for or showing interest. <br />
                                We will all stay in touch.
                            </div>
                            <Button onClick={handleRedirect}>Return to S2L Academy</Button>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default Form