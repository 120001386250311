import React, { useState } from "react";

const Truncate = ({ content }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  if (!content || content.length <= 150)
    return (
      <p className="mb-0 mt-0" style={{ minHeight: "128px" }}>
        {content}
      </p>
    );
  return (
    <div>
      <p style={{ minHeight: "100px" }} className="mb-0 mt-0">
        {isExpanded ? content : content.substring(0, 130) + "..."}
      </p>
      <div>
        <span
          onClick={toggleExpand}
          style={{
            color: "#81bef6",
            textDecoration: "underline",
            cursor: "pointer",
            fontSize: "18px",
          }}
        >
          {isExpanded ? "Read less" : "Read more"}
        </span>
      </div>
    </div>
  );
};

export default Truncate;
