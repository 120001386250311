import React from "react";
import "./UpcomingWorkshopSection.scss";
import { Button } from "primereact/button";
import UpcomingWorkshopBanner from "../../../../../Assets/Academy/upcoming-workshop.png";
import { useNavigate } from "react-router-dom";

const UpcomingWorkshopSection = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/workshops")
  }
  return (
    <div className="upcoming-workshop " id="upcoming-workshop-section">
      <div className="upcoming-workshop-content">
        <div className="upcoming-workshop-header flex flex-column ">
          <h2 className="upcoming-workshop-title font-bold">
            Upcoming Workshops
          </h2>
          <p className="upcoming-workshop-desc mt-0 mb-0">
            Dive Deep into Your Entrepreneurial Journey.
          </p>
        </div>

        <div className="upcoming-workshop-img mt-4">
          <img
            src={UpcomingWorkshopBanner}
            alt="upcoming-workshop-banner"
          ></img>
        </div>
        <div className="upcoming-workshop-button flex flex-row justify-content-center align-items-center mt-4">
          <Button label="Join the Free Workshops" onClick={handleRedirect} />
        </div>
      </div>
    </div>
  );
};

export default UpcomingWorkshopSection;
