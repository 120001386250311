import React from 'react';
import './Footer.scss';
import FooterLogo from '../../../Assets/Home Page/Startup2Launch-Logo-White-LB-2024_.png';
import VersionInfo from '../../../environments/versionInfo';


const Footer = () => {
    return (
        <>
            <div className="laulama-footer-section ml-0 mr-1 col-12 w-full">
                <div className='startup-logo col'>
                    <img src={FooterLogo} alt="Logo" />
                </div>
                <div className="quick-links col">
                    <h4 className='underline line'>Quick Links</h4>
                    <ul>
                        <li> <a href="/aboutus">About Us</a> </li>
                        <li> <a href="/plans">Plan</a> </li>
                        <li> <a href="/services">Services</a> </li>
                        {/* <li> <a href="/recruitment">Recruitment</a> </li> */}
                        <li> <a href="/s2lAcademy">Academy</a> </li>
                        <li> <a href="/Faq">FAQ</a> </li>
                    </ul>
                </div>

                <div className="legals col">
                    <h4 className='underline line'>Legals</h4>
                    <ul>
                        <li>
                            <a href="https://staticfile.startup2launch.com/Startup2launch+Terms+and+Conditions.pdf" target="_blank" >Terms and conditions</a>
                        </li>
                        <li>
                            <a href="https://staticfile.startup2launch.com/Startup2launch+Privacy+Policy.pdf" target="_blank" >Privacy Policy</a>
                        </li>
                    </ul>
                </div>

                <div className="contact col">
                    <h4 className='underline line'>Contact Us</h4>
                    <ul>
                        <li>
                            <span>Phone Number: (469) 271-9376</span>
                        </li>
                        <li>
                            <span>Email: info@startup2launch.com</span>
                        </li>
                    </ul>
                </div>

                <div className="social-media col">
                    <h4 className='line'>Connect with us</h4>
                    <ul>

                        <a href='https://www.instagram.com/startup2launchhq' target="_blank">
                            <i className="pi pi-instagram mr-3" style={{ fontSize: "1.5rem" }}></i>
                        </a>
                        <a href='https://www.facebook.com/profile.php?id=61553933195892' target="_blank">
                            <i className="pi pi-facebook mr-3" style={{ fontSize: "1.5rem" }}></i>
                        </a>
                        <a href='https://www.linkedin.com/company/startup2launch/?trk=feed_main-feed-card_feed-actor-name' target="_blank">
                            <i className="pi pi-linkedin mr-3" style={{ fontSize: "1.5rem" }}></i>
                        </a>
                        {/* <i className="pi pi-twitter mr-3" style={{ fontSize: "1.5rem" }}></i> */}
                    </ul>
                </div>
            </div>
            <div className='footer-copyright'>
                <div className="copyright-section">
                    {/* <div> */}
                    {/* <img src={FooterLogo} alt="Logo" /> */}
                    {/* <VersionInfo /> */}
                    {/* </div> */}
                    <p>All rights reserved by Syncronix Group</p>
                </div>
            </div>

        </>
    );
};

export default Footer;
