import { Button } from "primereact/button";
import React from "react";
import "./EnrollSection.scss";

const EnrollSection = ({ handleFormOpen }) => {
  return (
    <div id="enroll-section" className="enroll">
      <div className="enroll-content-main flex flex-column gap-3">
        <div className="enroll-content ">
          <span className="content-bold font-bold">
            S2L Academy’s Intensive Entrepreneurship Program
          </span>{" "}
          <span className=" content-normal">
            is an intensive business-planning training program designed to help
            participants who want to start a new business, grow an existing
            business, or make a substantial business pivot that requires viewing
            the business with a new perspective.
          </span>
        </div>
        <div className="enroll-button flex flex-row justify-content-center">
          <Button label="Enroll Now" onClick={handleFormOpen} />
        </div>
      </div>
    </div>
  );
};

export default EnrollSection;
