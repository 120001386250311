import React from "react";
import "./TicketSection.scss";
import { Button } from "primereact/button";

const TicketSection = ({ handleFormOpen }) => {
  return (
    <div className="ticket" id="ticket-section">
      <div className="ticket-section-main">
        <div className="ticket-header flex flex-column gap-2">
          <h2 className="ticket-title font-bold">Tickets</h2>
          <span className="ticket-dates">
            <span className="font-bold date-title">Dates: </span>
            <span className="date-content">
              {" "}
              September 22nd - November 17th, 2024
            </span>
          </span>

          <span className="ticket-location">
            <span className="font-bold location-title">Location: </span>
            <span className="location-content"> Dallas, TX</span>
          </span>
        </div>

        <div className="ticket-plan">
          <div className="ticket-plan-main">
            <div className="ticket-plan-card">
              <div className="plan-header">
                <span className="plan-title">Complete Payment</span>
                <div className="plan-amount mt-2">$500</div>
              </div>
              <div className="plan-details flex flex-column justify-content-center">
                <ul className="plan-ul flex flex-column gap-2">
                  <li>Complete Payment</li>
                  <li>
                    List all of the exciting benefits your customer will get if
                    they purchase this tier
                  </li>
                  <li>
                    What networking and learning opportunities are available to
                    them at the conference?
                  </li>
                  <li>What parts of the conference can they access?</li>
                  <li>Do they have access to any recorded programming?</li>
                  <li>
                    What are their food, transportation, and accommodation
                    options?
                  </li>
                </ul>
              </div>
              <div className="plan-enroll-button">
                <Button label="Enroll Now" className="entroll-button-main" onClick={handleFormOpen}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketSection;
