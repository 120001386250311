import React from "react";
import "./Courses.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import course2 from "../../../../../Assets/AcademyInternship/course2.jpg";

export default function Courses() {
  return (
    <div className="courses-main">
      <div className="courses-header">
        Startup2Launch Academy: Year-Long Career Progression for Middle and High
        School Students
      </div>

      <div className="courses-content">
        The Startup2Launch Academy offers a comprehensive year-long program to
        guide middle and high school students through a structured career
        progression in entrepreneurship. The program is divided into four main
        steps, each focusing on developing essential entrepreneurial skills,
        from foundational knowledge to launching their own startup.
        Additionally, the program offers an optional internship to provide
        real-world experience.
      </div>

      <div className="courses-body">
        <div className="courses-tab">
          <div>Course Framework</div>
        </div>
        <div className="courses-details">
          <div className="courses-details-sec-one">
            <div className="courses-details-sec-one-img">
              <div className="course-section-img-container">
                <img src={course2} />
              </div>
            </div>
            <div className="course-about-section">
              <div className="course-desc">
                Intensive Entrepreneurship Workshop with Capstone Project (8
                Weeks)
              </div>

              <div className="course-desc-content">
                Students will have practical knowledge of starting a business
                and will have completed a capstone project demonstrating their
                skills.
              </div>
              <div className="course-obj">Objective:</div>
              <div className="course-obj-content">
                Provide in-depth knowledge and practical experience in
                entrepreneurship.
              </div>
            </div>
          </div>
          <div className="courses-details-sec-two">
            <div className="course-components">Key Components:</div>
            <div className="new">
              <div className="col news ">
                <div className="details-container col-6 ">
                  <div className="event-icon">
                    <FontAwesomeIcon
                      icon={faCalendarDays}
                      className="calendar-icon"
                    />
                  </div>
                  <div className="event-details">
                    <div className="event-duration">
                      Week 1-2: Business Planning and Market Research
                    </div>
                    <div className="event-progress">
                      Market analysis, identifying target customers, and
                      competitive analysis.
                    </div>
                  </div>
                </div>
                <div className="details-container col-6">
                  <div className="event-icon">
                    <FontAwesomeIcon
                      icon={faCalendarDays}
                      className="calendar-icon"
                    />
                  </div>
                  <div className="event-details">
                    <div className="event-duration">
                      Week 3-4: Product Development and Prototyping
                    </div>
                    <div className="event-progress">
                      Developing a product/service, creating prototypes, and
                      iterative testing.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="new">
              <div className="col news">
                <div className="details-container col-6 ">
                  <div className="event-icon">
                    <FontAwesomeIcon
                      icon={faCalendarDays}
                      className="calendar-icon"
                    />
                  </div>
                  <div className="event-details">
                    <div className="event-duration">
                      Week 5-6: Marketing and Sales Strategies
                    </div>
                    <div className="event-progress">
                      Branding, marketing channels, and sales tactics.
                    </div>
                  </div>
                </div>
                <div className="details-container col-6 ">
                  <div className="event-icon">
                    <FontAwesomeIcon
                      icon={faCalendarDays}
                      className="calendar-icon"
                    />
                  </div>
                  <div className="event-details">
                    <div className="event-duration">
                      Week 7: Financial Literacy and Funding
                    </div>
                    <div className="event-progress">
                      Basic accounting, budgeting, and understanding funding
                      options.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="new">
              <div className="col news ">
                <div className="details-container col-6 ">
                  <div className="event-icon">
                    <FontAwesomeIcon
                      icon={faCalendarDays}
                      className="calendar-icon"
                    />
                  </div>
                  <div className="event-details">
                    <div className="event-duration">
                      Week 8: Capstone Project
                    </div>
                    <div className="event-progress">
                      Students will form teams to create a business plan and
                      pitch their startup idea to a panel of judges.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
